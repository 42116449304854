<!--
 * @Author: Jerry
 * @Date: 2021-01-27 09:33:10
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-08 15:36:50
 * @Description: file content
-->
<template>
  <div class="orderall">
    <div class="searchDiv">
      <div class="ssssSborder">
        <div class="ssssSearch">
          <el-input
            class="searchinput"
            placeholder="商品名称、商品编号、订单号"
            v-model="extraParam.searchKey"
          ></el-input>
        </div>
        <div class="searchicon bg_f4f4">
          <i class="el-icon-search co_000 curPointer" @click="loadData()"></i>
        </div>
      </div>
    </div>
    <order-title> </order-title>
    <div class="orderlists">
      <order-lists :dataSource="dataSource" @refreshData="refreshData"></order-lists>
    </div>
    <div class="grid Mtp40 Mbm40 floatR">
      <el-pagination
        background
        :total="ipagination.totalCount"
        :page-size="ipagination.size"
        :current-page="ipagination.current"
        @size-change="onSizeChange"
        @current-change="onPageChange"
        layout="total, sizes, prev, pager, next"
      ></el-pagination>
    </div>
    <div class="clearfix"></div>
  </div>
</template>
<script>
import OrderLists from "@/components/myOrder/orderLists.vue";
import OrderTitle from "@/components/myOrder/orderTitle";

import { listMixin } from "@/mixins/listMixins";
import { getBuyerOrderPageBuy, getBuyerOrderPageSell } from "@/api/user";

import { mapGetters } from "vuex";
export default {
  mixins: [listMixin],
  components: { OrderTitle, OrderLists },
  data() {
    return {
      extraParam: {
        userId: "",
        orderStatus: '3',
        searchKey: ""
      },
      url: {
        list: '',
      },
    };
  },
  computed: { 
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.extraParam.userId = this.userInfo.id;
    this.url.list = this.userInfo.roleType == '1' ? getBuyerOrderPageSell : getBuyerOrderPageBuy;
    this.loadData();
  },
};
</script>
<style lang="scss" scoped>
</style>
